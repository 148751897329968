<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:item="{ item }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ item.Address }}</td>
                            <td>{{ hideBodyInfo(item.Body) }}</td>
                            <td v-if="adminRole || paymentRole">
                                <v-edit-dialog :return-value.sync="item.RechargeID" large lazy persistent @save="saveItem({'ID': item.ID, 'RechargeID': item.RechargeID})">
                                <div>{{ item.RechargeID }}</div>
                                <template v-slot:input>
                                    <v-text-field v-model="item.RechargeID" label="Lệnh nạp tiền" single-line autofocus></v-text-field>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td v-else>{{ item.RechargeID }}</td>
                            <td v-if="adminRole || paymentRole">
                                <v-edit-dialog :return-value.sync="item.Status" large lazy persistent @save="saveItem({'ID': item.ID, 'Status': item.Status})">
                                <div v-bind:style="{ 'color': get_status_color(item.Status, sms_types)}">{{ item.Status | display_value(sms_types) }}</div>
                                <template v-slot:input>
                                    <v-select v-model="item.Status" :items="sms_types" item-text="name" item-value="value" label="Trạng thái" single-line autofocus></v-select>
                                </template>
                                </v-edit-dialog>
                            </td>
                            <td v-else v-bind:style="{ 'color': get_status_color(item.Status, sms_types)}">{{ item.Status | display_value(sms_types) }}</td>
                            <td>
                                {{ item.SentDate | display_date('DD-MM-YYYY')}}
                                <br />
                                {{ item.SentDate | display_date('HH:mm:ss')}}
                            </td>
                            <td><v-icon medium @click="deleteItem(item.ID)">fa-trash-alt</v-icon></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "@/configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";

export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        return {
            sms_types: configs.SMS_TYPE_LIST,
            headers: [
                {
                    text: "Bank",
                    value: "Address",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Nội dung",
                    value: "Body",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Lệnh nạp",
                    value: "RechargeID",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Trạng thái",
                    value: "Status",
                    filterable: true,
                    values: configs.SMS_TYPE_LIST,
                    dataType: configs.DATA_TYPE["Enum"]
                },
                {
                    text: "Ngày nhận",
                    value: "SentDate",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "",
                    value: ""
                }
            ],
            pagination: { ...configs.PAGINATION, sortBy: ["SentDate"]},
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "bankmessages_list",
            filterConditions: [],
            quickFilterConditions: [],
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
    },
    computed: {
        ...mapState({
            items: state => state.bank_message.all.data,
            total_rows: state => state.bank_message.all.total,
            loading: state => state.bank_message.loading,
            currentItem: state => state.bank_message.selected
        }),
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        paymentRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Payment) == configs.ROLE_TYPE.Payment;
        },
    },
    methods: {
        filter_data: function () {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("bank_message/getList", param);
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        get_status_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
        saveItem(obj) {
            this.$store.dispatch("bank_message/setDetail", {data: obj, id: obj.ID});
        },
        deleteItem: function(id) {
            if(this.adminRole) {
                window.getApp.$emit("REQUEST_DELETE_DATA", id);
            }
            else {
                sendErrorNotice('Bạn không có quyền xóa sms.')
            }
        },
        hideBodyInfo(body) {
            if(body) {
                let tmp = body.split('.');
                if (tmp.length > 2) {
                    tmp.splice(1,1);
                    return tmp.join('.');
                }
            }
            return body;
        }
    },
    mounted() {
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("bank_message/removeDetail", id);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
